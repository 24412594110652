<template>
  <div>
    <div class="contentsWidth mx-auto mt-4">
      <div class="">
        <p class="title mb-0">合否設定 - 一覧</p>
        <hr class="title">
      </div>

      <div id="serch-wrap" class="mt-4">
        <div class="bold">絞り込み条件</div>
        <div class="search-area flex flexCenter">

          <div class="search-middle inlineBlock search-space">
            <div>年度</div>
            <div>
              <b-form-select
                @change="resetFilter(type='year')"
                :options="yearList"
                :value="filter.year"
                @input="setFilter('year', $event)"
                v-model="filter.year">
              </b-form-select>
            </div>
          </div>

          <div class="search-middle-long inlineBlock search-space">
            <div>助成種別</div>
            <div>
                <!-- :options="apptypeListData[filter.year]" -->
              <b-form-select
                @change="resetFilter(type='apptype')"
                :value="filter.apptype"
                @input="setFilter('apptype', $event)"
                :options="apptypeList">
                <template v-slot:first>
                  <option :value="null">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>

          <div class="search-middle-long inlineBlock search-space">
            <div>選考段階</div>
            <div>
              <b-form-select
                @change="resetGroupFilter()"
                :value="filter.stage"
                @input="setFilter('stage', $event);fetchAppList()"
                :options="stageList">
                <template v-slot:first>
                  <option :value="null">
                    <span v-if="stageList.length > 0">-- 未選択 --</span>
                    <span v-if="stageList.length <= 0">助成種別を選択してください</span>
                  </option>
                </template>
              </b-form-select>
            </div>
          </div>

          <div class="inlineBlock search-space">
            <div>合否</div>
            <div>
              <b-form-select
                :value="filter.pass"
                @input="setFilter('pass', $event)"
                :options="judgmentOption">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>

          <div v-if="isUseGroup" class="inlineBlock">
            <div>グループ</div>
            <div>
              <b-form-select
                @input="setFilter('group', $event)"
                :value="filter.group"
                :options="groupList">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>

        </div>
      </div>

      <div class="contentsWidth mt-2">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="evalTable"
            :value="currentPage"
            @input="setCurrentPage($event)"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                :value="perPage"
                @change="setPerPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>

        <b-table striped hover
          id='cmsJudgmentTable'
          table-class="cmsJudgmentTable"
          thead-class="tableHead"
          tbody-tr-class="dataWrap"
          :items="appList"
          :fields="isUseGroup ? header2 : header"
          :filter="filter"
          :filter-function="filtering"
          @filtered="onFiltered"
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
          :sort-null-last="true"
          :sort-compare="sortCompare"
          @sort-changed="onSortChanged"
        >
          <template
            v-slot:head(checkbox)>
            <b-link
              id="popover"
              class="ml-1 select-pop-over-trigger">選択</b-link>
            <b-popover
              target="popover"
              title=""
              triggers="hover"
              placement="right"
            >
              <template v-slot:default>
                <p class="mb-0 select-pop-over" @click="pageSelect">ページ内全選択</p>
                <p class="mb-0 select-pop-over" @click="pageRemove">ページ内全解除</p>
                <p class="mb-0 select-pop-over" @click="allSelect">全選択</p>
                <p class="mb-0 select-pop-over" @click="allRemove">全解除</p>
              </template>
            </b-popover>
          </template>
          <template v-slot:cell(checkbox)="row">
            <div class="flex flexCenter">
              <b-form-checkbox
                @change="updateSelectedAppIdList($event, row.item.app_id)"
                :checked="selectedAppIdList"
                :value="row.item.app_id"
                />
            </div>
          </template>
          <template v-slot:emptyfiltered="scope">
            <div class="flex flexCenter">{{noDataMsg}}</div>
          </template>
          <!-- kanaでソートをするが、表示を漢字名 -->
          <template v-slot:cell(kana)="row">
            {{row.item.name}}
          </template>
          <template v-slot:cell(done)="row">
            <span v-if="row.item.count_assign>0">
              {{row.item.done}}({{row.item.done_eval}} / {{row.item.count_assign}})
            </span>
          </template>
          <template v-slot:cell(pass)="row">
            {{judgmentStatusText[row.item.pass] || ''}}
          </template>
          <template v-slot:cell(btn)="row">
            <b-link :to="`/cms/applications/judgment/edit/${row.item.app_id}/${row.item.stage_id}`">
              編集
            </b-link>
          </template>
        </b-table>
      </div>

      <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
        <b-button
        class="btn btn-lg bold"
        to="/cms/top">トップに戻る</b-button>
        <b-button
          variant="primary"
          class="btn btn-primary btn-lg bold ml-2"
          :disabled="!canExportCsv"
          @click="exportCsv()"
          >CSV出力</b-button>
        <b-button
          variant="primary"
          class="btn btn-primary btn-lg bold ml-2"
          :disabled="!canExportPdf"
          @click="exportPdf()"
          >PDF出力</b-button>
        <b-button
          variant="primary"
          class="btn btn-primary btn-lg bold ml-2"
           v-b-modal.judgementModal
            >一括合否設定</b-button>
        <b-button
          v-if="isUseGroup"
          variant="primary"
          class="btn btn-primary btn-lg bold ml-2"
          :disabled="!canGroupSetting"
          @click="groupSetting()"
          >グループ設定</b-button>
      </div>
    </div>


    <b-modal
      id="judgementModal"
      title-class="titleMsg"
      body-class="modalBox"
      content-class="p-2"
      title=""
      size="sm"
      ok-title=""
      ok-variant="secondary"
      :hide-header="true"
      :hide-footer="true"
      :centered="true"
    >
      <div class="my-3 flex flexCenter mx-auto">
        <b-button
          variant="primary"
          class="btn btn-primary btn-lg bold"
          @click="judgment(1)"
          >合格</b-button>
        <b-button
          class="btn btn-primary btn-lg bold ml-2"
          @click="judgment(0)"
          >不合格</b-button>
      </div>
    </b-modal>
    <b-modal
      id="groupModal"
      ref="groupSettingModal"
      size="lg"
      title="グループ設定"
      title-class="groupSettingTitleModal">
      <div class="flex groupSelectItem">
        <div class="title">グループ：</div>
        <div>
          <b-form-select
          v-model="modalGroupSelected"
          :options="groupList">
            <template v-slot:first>
              <option :value="''">-- 未選択 --</option>
            </template>
          </b-form-select>
        </div>
      </div>
      <template #modal-footer="{ ok, cancel}">
        <b-button class="btn btn-lg bold" @click="cancel()">
          キャンセル
        </b-button>
        <b-button class="btn btn-primary btn-lg bold ml-2" size="md" variant="success" @click="groupSetSave()">
          設定
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import CONST_STATUS from '@/constants/appStatus';
import download from '@/modules/download';

export default {
  name: 'CmsApplicationsJudgmentList',
  data() {
    return {
      yearList: [],
      appList: [],
      apptypeListData: {},
      header: [
        { key: 'checkbox', label: '全選択／全解除', sortable: false },
        { key: 'kana', label: '申請者名', sortable: true },
        { key: 'institution_name', label: '所属', sortable: true },
        { key: 'theme', label: '研究テーマ', sortable: true },
        { key: 'done', label: '選考状況', sortable: true },
        { key: 'score', label: '得点', sortable: true },
        { key: 'pass', label: '合否', sortable: true },
        { key: 'btn', label: '', sortable: false },
      ],
      header2: [
        { key: 'checkbox', label: '全選択／全解除', sortable: false },
        { key: 'kana', label: '申請者名', sortable: true },
        { key: 'institution_name', label: '所属', sortable: true },
        { key: 'theme', label: '研究テーマ', sortable: true },
        { key: 'done', label: '選考状況', sortable: true },
        { key: 'score', label: '得点', sortable: true },
        { key: 'pass', label: '合否', sortable: true },
        { key: 'group', label: 'グループ', sortable: true },
        { key: 'btn', label: '', sortable: false },
      ],
      isInit: true,
      oldPage: 1,
      seriesId: null,
      groupList: [],
      modalGroupSelected: '',
      sortBy: null,
      sortDesc: false,
      tableShowData: [],
    };
  },
  methods: {
    onSortChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      console.log(ctx.sortBy);
      console.log(ctx.sortDesc);
      this.setSortedData();
    },
    setSortedData() {
      if (this.sortBy) {
        const sortedData = this.tableShowData.slice().sort((a, b) => {
          const aValue = a[this.sortBy];
          const bValue = b[this.sortBy];
          const key = this.sortBy;
          let result;
          if (key === 'pass' && (aValue === null || bValue === null)) {
            if (aValue === null && bValue === null) {
              result = 0;
            } else if (aValue === null) {
              result = 1;
            } else if (bValue === null) {
              result = -1;
            }
            return this.sortDesc ? -result : result;
          }

          if (aValue === null && bValue === null) {
            result = a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' });
          } else if (aValue === null) {
            result = this.sortDesc ? -1 : 1; // nullを常に最後に
          } else if (bValue === null) {
            result = this.sortDesc ? 1 : -1; // nullを常に最後に
          } else if (typeof aValue === 'number' && typeof bValue === 'number') {
            result = aValue - bValue;
          } else {
            result = aValue.toString().localeCompare(bValue.toString(), undefined, { numeric: true, sensitivity: 'base' });
          }
          return this.sortDesc ? -result : result;
        });
        this.$store.commit('cmsJudgmentSearch/setFilterdIdList', sortedData);
      }
    },
    async initFetch() {
      const param = {
        year: this.filter.year,
        apptype: this.filter.apptype,
        stage: this.filter.stage,
      };
      const promiseFuncs = [
        api.send('/api/cms/judgment/init', param),
      ];
      if (!this.noSelect) {
        promiseFuncs.push(api.send('/api/cms/judgment/applications/list', param));
      }
      const responses = await api.all(promiseFuncs)
        .catch((err) => {
          console.log(err);
        });
      if (!responses) {
        return;
      }
      this.yearList = responses[0].data.yearList;
      // 年度がすでに選択されていなければ、最新の年度を選択状態にする
      if (this.filter.year === null && this.yearList.length > 0) {
        const yearParam = { key: 'year', value: this.yearList[0] };
        this.$store.commit('cmsJudgmentSearch/setFilter', yearParam);
      }
      this.apptypeListData = responses[0].data.apptypeListData;
      if (!this.noSelect) {
        this.appList = responses[1].data.appList;
      }
      this.groupList = responses[0].data.groupList;
    },
    async fetchAppList() {
      if (this.noSelect) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const param = {
        year: this.filter.year,
        apptype: this.filter.apptype,
        stage: this.filter.stage,
      };
      const response = await api.send('/api/cms/judgment/applications/list', param)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      this.appList = response.data.appList;
      this.seriesId = response.data.seriesId;
    },
    filtering(lineData, filter) {
      const passMatch = filter.pass === '' || String(lineData.pass) === filter.pass;
      const passUnset = lineData.pass === null && filter.pass === null;
      const group = !this.isUseGroup || filter.group === '' || lineData.group_id === Number(filter.group);
      return (passMatch || passUnset) && group;
    },
    onFiltered(filteredItems) {
      this.$store.commit('cmsJudgmentSearch/setTotalRows', filteredItems.length);
      if (this.isInit) {
        this.$store.commit('cmsJudgmentSearch/setCurrentPage', this.oldPage);
      } else {
        this.$store.commit('cmsJudgmentSearch/setCurrentPage', 1);
      }
      this.$store.commit('cmsJudgmentSearch/initSelectedAppid');
      // 編集ページ内での次の申請、前の申請に移動するためのIDリストの保存
      this.$store.commit('cmsJudgmentSearch/setFilterdIdList', filteredItems);
      this.tableShowData = filteredItems;
      this.setSortedData();
    },
    updateSelectedAppIdList(event, appId) {
      if (!event) {
        this.$store.commit('cmsJudgmentSearch/removeSelectedAppid', appId);
      } else {
        this.$store.commit('cmsJudgmentSearch/addSelectedAppid', appId);
      }
    },
    allSelect() {
      this.$store.commit('cmsJudgmentSearch/allSelectedAppid');
    },
    allRemove() {
      this.$store.commit('cmsJudgmentSearch/initSelectedAppid');
    },
    pageSelect() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdList.slice(start, end);
      idListOnPage.forEach((appId) => {
        this.$store.commit('cmsJudgmentSearch/addSelectedAppid', appId);
      });
    },
    pageRemove() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdList.slice(start, end);
      idListOnPage.forEach((appId) => {
        this.$store.commit('cmsJudgmentSearch/removeSelectedAppid', appId);
      });
    },
    setFilter(key, value) {
      const param = { key, value };
      this.$store.commit('cmsJudgmentSearch/setFilter', param);
    },
    resetFilter(type) {
      const param = { value: null };
      if (type === 'year') {
        param.key = 'apptype';
        this.$store.commit('cmsJudgmentSearch/setFilter', param);
      }
      param.key = 'stage';
      this.$store.commit('cmsJudgmentSearch/setFilter', param);
      param.key = 'status';
      this.$store.commit('cmsJudgmentSearch/setFilter', param);
      this.appList = [];
      this.$store.commit('cmsJudgmentSearch/setTotalRows', 0);
      if (type === 'apptype') {
        const stageParam = { key: 'stage', value: null };
        this.$store.commit('cmsJudgmentSearch/setFilter', stageParam);
      }
      this.resetGroupFilter();
    },
    resetGroupFilter() {
      const param = { value: '' };
      param.key = 'group';
      this.$store.commit('cmsJudgmentSearch/setFilter', param);
    },
    async exportCsv() {
      if (!this.canExportCsv) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const sendPass = this.filter.pass === null ? 'noneJudge' : this.filter.pass;
      const params = {
        apptype: this.filter.apptype,
        stage: this.filter.stage,
        pass: sendPass,
        group: this.filter.group,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/judgment/export/csv', params, requireBlob)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      const ymd = moment().format('YYYYMMDD');
      const apptype = this.apptypeList.find(data => data.value === this.filter.apptype);
      const fileName = `${ymd}_${apptype.text}_${this.filter.stage}次.csv`;
      download.csvUtf(response.data, fileName);
    },
    // eslint-disable-next-line
    sortCompare(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
      if (key === 'done' || key === 'score') {
        if (aRow[key] === null && bRow[key] === null) {
          return aRow.name.localeCompare(bRow.name, undefined, { numeric: true, sensitivity: 'base' });
        }
        if (sortDesc && aRow[key] === null) {
          return -1;
        }
        if (sortDesc && bRow[key] === null) {
          return +1;
        }
      }
      return false;
    },
    setCurrentPage(page) {
      this.$store.commit('cmsJudgmentSearch/setCurrentPage', page);
    },
    setPerPage(page) {
      this.$store.commit('cmsJudgmentSearch/setPerPage', page);
    },
    setTotalRows(value) {
      this.$store.commit('cmsJudgmentSearch/setTotalRows', value);
    },
    async judgment(pass) {
      const text = pass === 1 ? '合格' : '不合格';
      const count = this.selectedAppIdList.length;
      const msg = `選択中の${count}件の申請を${text}に変更します。`;
      if (!await this.confirm(msg)) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const params = {
        apptype: this.filter.apptype,
        stage: this.filter.stage,
        pass,
        selectedAppIdList: this.selectedAppIdList,
      };
      const response = await api.send('/api/cms/judgment/control/many', params)
        .catch(async (err) => {
          if (err.response.status === 422) {
            let errMsg = '合否設定に失敗しました。エラーの内容は以下です。';
            if (err.response.data.messages) {
              Object.keys(err.response.data.messages).forEach((key) => {
                errMsg += `\n・${err.response.data.messages[key]}`;
              });
            }
            await this.alert(errMsg, false);
          }
        });
      this.$bvModal.hide('judgementModal');
      if (!response) {
        this.$store.dispatch('page/offWaiting');
        return;
      }
      await this.alert('合否設定を行いました。', false);
      this.$store.dispatch('page/offWaiting');
      await this.fetchAppList();
    },
    async exportPdf() {
      if (!this.canExportPdf) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const params = {
        selectedAppIdList: this.selectedAppIdList,
        seriesId: this.seriesId,
        year: this.filter.year,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/judgment/export/listpdf', params, requireBlob)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        // eslint-disable-next-line
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      const ymd = moment().format('YYYYMMDD');
      const apptype = this.apptypeList.find(data => data.value === this.filter.apptype);
      const fileName = `${ymd}_${apptype.text}.pdf`;
      download.blob(response.data, fileName);
    },
    groupSetting() {
      if (!this.canGroupSetting) {
        return;
      }
      this.$refs.groupSettingModal.show();
    },
    async groupSetSave() {
      this.$store.dispatch('page/onWaiting');
      const params = {
        selectedAppIdList: this.selectedAppIdList,
        selectedGroup: this.modalGroupSelected,
        apptype: this.filter.apptype,
        year: this.filter.year,
        stage: this.filter.stage,
      };
      const response = await api.send('/api/cms/judgment/groupSave', params)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert(err.response.data.errMsg);
          } else {
            await this.alert('グループの設定に失敗しました。');
          }
          return false;
        });
      if (!response) {
        this.$store.dispatch('page/offWaiting');
        return;
      }
      await this.alert('グループの設定が完了しました。');
      this.$refs.groupSettingModal.hide();
      await this.initFetch();
      this.$store.dispatch('page/offWaiting');
    },
  },
  computed: {
    filterdIdList() {
      return this.$store.state.cmsJudgmentSearch.filterdIdList;
    },
    selectedAppIdList() {
      return this.$store.state.cmsJudgmentSearch.selectedAppIdList;
    },
    filter() {
      return this.$store.state.cmsJudgmentSearch.judgmentFilter;
    },
    currentPage() {
      return this.$store.state.cmsJudgmentSearch.currentPage;
    },
    perPage() {
      return this.$store.state.cmsJudgmentSearch.perPage;
    },
    totalRows() {
      return this.$store.state.cmsJudgmentSearch.totalRows;
    },
    pageOptions() {
      return [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
      ];
    },
    apptypeList() {
      if (!this.apptypeListData[Number(this.filter.year)]) {
        return [];
      }
      const apptypeList = this.apptypeListData[Number(this.filter.year)].map((data) => {
        return { value: data.id, text: data.name };
      });
      return apptypeList;
    },
    stageList() {
      if (!this.apptypeListData[Number(this.filter.year)] || this.filter.apptype === null) {
        return [];
      }
      const apptypes = this.apptypeListData[Number(this.filter.year)];
      const index = apptypes.findIndex((data) => {
        return this.filter.apptype === data.id;
      });
      if (!apptypes[index] || !apptypes[index].evaluation_stages) {
        return [];
      }
      const stageList = apptypes[index].evaluation_stages.map((data) => {
        return {
          value: data.stage,
          text: `${data.stage}次`,
          is_last: data.is_last,
        };
      });
      return stageList;
    },
    statusText() {
      return CONST_STATUS.APP_STATUS_TEXTS;
    },
    noSelect() {
      const nullYear = this.filter.year === null;
      const nullApptype = this.filter.apptype === null;
      const nullStage = this.filter.stage === null;
      return nullYear || nullApptype || nullStage;
    },
    noDataMsg() {
      if (this.noSelect) {
        return '年度、助成種別、選考段階を選択してください。';
      }
      return '条件に一致するデータがありません。';
    },
    judgmentStatusText() {
      return CONST_STATUS.JUDGMENT_STATUS_OPTION;
    },
    judgmentOption() {
      const text = CONST_STATUS.JUDGMENT_STATUS_OPTION;
      const option = [];
      option.push({ value: null, text: '未設定' });
      option.push({ value: '1', text: text['1'] });
      option.push({ value: '0', text: text['0'] });
      return option;
    },
    canExportCsv() {
      return this.filter.apptype && this.filter.stage;
    },
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
    canExportPdf() {
      const { year, apptype } = this.filter;
      const number = this.selectedAppIdList.length > 0;
      return number && apptype && year !== 'all';
    },
    isUseGroup() {
      const { year, apptype, stage } = this.filter;
      if (!year || !apptype || !stage) {
        return false;
      }
      if (!this.apptypeListData[Number(year)]) {
        return false;
      }
      const targetAppType = this.apptypeListData[Number(year)].find((data) => {
        return apptype === data.id;
      });
      if (typeof targetAppType === 'undefined') {
        return false;
      }
      return Number(targetAppType.isUseGroup) === 1 && stage === 2;
    },
    canGroupSetting() {
      const { year, apptype, stage } = this.filter;
      const number = this.selectedAppIdList.length > 0;
      return number && apptype && year !== 'all' && stage === 2;
    },
  },
  watch: {
    'filter.year': function (val) {
      if (val === null) {
        this.initFetch();
      }
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    this.oldPage = this.currentPage;
    await this.initFetch();
    this.isInit = false;
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style>
  #cmsJudgmentTable thead th:nth-of-type(1) {
    width: 60px !important;
    font-size: 13px;
  }
  #cmsJudgmentTable thead th:nth-of-type(2) {
    width: 160px !important;
  }
  #cmsJudgmentTable thead th:nth-of-type(3) {
    width: 160px !important;
  }
  #cmsJudgmentTable thead th:nth-of-type(4) {
    width: 440px !important;
    max-width: 440px !important;
  }

  #cmsJudgmentTable tbody td:nth-of-type(5) {
    width: 160px !important;
    max-width: 160px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  #cmsJudgmentTable thead th:nth-of-type(6) {
    width: 120px !important;
  }
  #cmsJudgmentTable thead th:nth-of-type(7) {
    width: 120px !important;
  }

  #cmsJudgmentTable thead th:nth-of-type(8) {
    width: 80px !important;
    max-width: 80px !important;
  }

  #groupModal .modal-footer{
    justify-content: center !important;
  }
  #groupModal___BV_modal_outer_ {
    z-index: 999 !important;
  }
</style>

<style scoped>
  #serch-wrap input, #serch-wrap select {
    height: 50px;
  }

  .year-select {
    height: 40px;
    width: 120px;
  }

  .search-area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }
  .search-middle {
    width: 290px;
  }

  .search-middle-long {
    width: 300px;
  }
  .groupSelectItem {
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .groupSelectItem .title {
    margin-right: 10px;
  }
  .groupSelectItem select {
    font-size: 1.5rem;
  }
</style>
